.footercompoment{
    min-height: 200px;
    background-color: #2D2D2D;
    display: flex;
    p{
        color:gray
    }
    .footer_left{
       padding: 10px 0px;
    }
.footer_left{
    width:75%;

}
.footer_right{
    width:25%;
    margin-left: 10px;
}
}
