.header-component {
  background-color: #343a40;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}

.header-inner {
  display: flex;
  align-items: center;
  width: 100%;
  width:80%;
  margin:auto;
}

.logo {
  margin-right: 20px;
}

.logo-image {
  max-height: 70px;
}

.header-search {
  flex-grow: 1;
  position: relative;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid gray;
  background-color: #27272a;
  color: #fff;
}

.search-icon {
  margin-left: -30px;
  color: white;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.search-result-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.result-image {
  width: 40px;
  border-radius: 4px;
  margin-right: 10px;
}

.result-title {
  font-weight: bold;
  color: #fff;
}

.result-subtitle {
  color: gray;
}

.view-all-results {
  text-align: center;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;
}

.header-nav {
  display: flex;
  align-items: center;
}

.nav-dropdown {
  position: relative;
  margin-left: 15px;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #343a40;
  color: white;
  border-radius: 8px;
  list-style: none;
  padding: 10px;
  width: 300px; /* Adjust width as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: none; /* Hidden by default */
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px; /* Space between columns */
}

.nav-dropdown:hover .dropdown-menu,
.nav-dropdown:focus-within .dropdown-menu {
  display: grid; /* Show on hover or focus */
}

.dropdown-item {
  color: white;
  text-decoration: none;
  padding: 3px 10px;
  display: block;
  border-radius: 4px; /* Optional: rounded corners for items */
}

.dropdown-item:hover {
  background-color: #495057;
}



.header-icons {
  display: flex;
  align-items: center;
}

.login-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.login-icon {
  margin-left: 5px;
}

.bookmark-icon {
  margin-left: 15px;
}
.dropdown-menu.show {
  display: grid; /* Show on toggle */
}
.hamburger-icon {
  display: none; /* Hide by default */
}
.tab_menu {
  display: none;
}

@media (max-width: 768px) {
  .tab_menu {
    display: block !important;
    position: absolute;
    margin-top: 76px;
    right: 10px;
    margin-top: 100px;
  }
  
  .header-nav {
    display: none;
    flex-direction: column;
    opacity: 0;
    position: relative;

    &.visible {
      display: flex;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &.hidden {
      display: none;
      opacity: 0;
    }
  }
  .header-inner {
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    margin-bottom: 10px;
  }
  
  .header-search {
    width: 90%;
    margin-bottom: 10px;
  }
  
  .header-nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .nav-dropdown {
    width: 100%;
  }
  
  .dropdown-toggle {
    width: 100%;
    text-align: left;
    padding: 10px;
  }
  
  .dropdown-menu {
    position: static;
    width: 100%;
    box-shadow: none;
    border: none;
    background-color: transparent;
  }
  
  .dropdown-item {
    border-bottom: 1px solid #ddd;
  }
  
  .tab_menu {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}


.user-menu {
  position: relative;
  display: inline-block;

  &:hover .dropdown-menu {
    display: block; 
  }
}

.dropdown-menu {
  display: none; 
  position: absolute;
  background-color: #f9f9f9;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-item {
  color: black;
  padding: 7px 10px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #f1f1f1;
  }
}

/* RegisterVIP2.scss or your relevant CSS file */

@keyframes colorChange {
  0% { color: yellow; }
  50% { color: red; }
  100% { color: yellow; }
}

.dynamic-text {
  font-size: 14px;
  text-decoration: none;
  animation: colorChange 2s infinite; /* 2 seconds for a full cycle, infinite loop */
}
